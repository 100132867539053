var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("一番賞管理")]),_c('v-row',[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","dense":"","hide-details":""},on:{"click":_vm.addRecord}},[_vm._v(" 新增 ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3","md":"2"}},[_c('v-select',{attrs:{"label":"店點","items":_vm.branches,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"2"}},[_c('v-select',{attrs:{"label":"上下架狀態","items":[
          { text: '全部', value: null },
          { text: '上架', value: true },
          { text: '下架', value: false },
        ],"outlined":"","dense":"","hide-details":""},model:{value:(_vm.isAvailable),callback:function ($$v) {_vm.isAvailable=$$v},expression:"isAvailable"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"2"}},[_c('v-select',{attrs:{"label":"銷售狀態","items":[
          { text: '全部', value: null },
          { text: '完售｜出獎', value: true },
          { text: '未完售', value: false },
        ],"outlined":"","dense":"","hide-details":""},model:{value:(_vm.isSoldout),callback:function ($$v) {_vm.isSoldout=$$v},expression:"isSoldout"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"2"}},[_c('v-select',{attrs:{"label":"管道","items":[
          { text: '全部', value: null },
          { text: '線上', value: true },
          { text: '線下', value: false },
        ],"outlined":"","dense":"","hide-details":""},model:{value:(_vm.isOnline),callback:function ($$v) {_vm.isOnline=$$v},expression:"isOnline"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"關鍵字","placeholder":"搜尋: 名稱, 編號","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterData,"mobile-breakpoint":0,"item-key":"index","footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [30, 100, -1],
        }},scopedSlots:_vm._u([{key:"item.isAvailable",fn:function({ item }){return [_c('v-checkbox',{staticClass:"empty-label",on:{"change":function($event){return _vm.update(item, 'isAvailable')}},model:{value:(item.isAvailable),callback:function ($$v) {_vm.$set(item, "isAvailable", $$v)},expression:"item.isAvailable"}})]}},{key:"item.isOnline",fn:function({ item }){return [_c('v-checkbox',{staticClass:"empty-label",attrs:{"disabled":""},model:{value:(item.isOnline),callback:function ($$v) {_vm.$set(item, "isOnline", $$v)},expression:"item.isOnline"}})]}},{key:"item.status",fn:function({ item }){return [(
              item.sold === item.count ||
              item.rewards.every((item) => item.hit)
            )?_c('span',{staticClass:"red--text"},[_vm._v("完售 ")]):_vm._e()]}},{key:"item.plans",fn:function({ item }){return _vm._l((item.plans),function(plan){return _c('div',[_vm._v(" "+_vm._s(plan.count)+"抽 - $"+_vm._s(plan.amount)+" ")])})}},{key:"item.rewards",fn:function({ item }){return _vm._l((item.rewards),function(reward){return _c('div',[_c('span',{class:{ 'red--text': reward.hit }},[_vm._v("#"+_vm._s(reward.number)+" - "+_vm._s(reward.reward))])])})}},{key:"item.sold",fn:function({ item }){return [_c('div',{class:{ 'red--text': item.sold === item.count }},[_vm._v(" "+_vm._s(item.sold)+" / $"+_vm._s(item.amount)+" ")])]}},{key:"item.userID",fn:function({ item }){return [(item.userID)?_c('div',[_vm._v(" "+_vm._s(`${_vm.mapUser[item.userID]?.name || ""} (${ _vm.mapUser[item.userID]?.phone || "" })`)+" ")]):_c('div',[_vm._v("---")])]}},{key:"item.update",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDialog(item, 'ichiban')}}},[_vm._v(" 編輯 ")])]}},{key:"item.delete",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteIchiban(item)}}},[_vm._v("刪除")])]}},{key:"item.display",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openDialog(item, 'display')}}},[_vm._v(" 展示圖 ")])]}}])})],1)],1),(_vm.dialog.ichiban)?_c('DialogIchiban',{attrs:{"item":_vm.selectedItem,"branches":_vm.accessBranch,"owners":_vm.owners,"users":_vm.users},on:{"load":_vm.load},model:{value:(_vm.dialog.ichiban),callback:function ($$v) {_vm.$set(_vm.dialog, "ichiban", $$v)},expression:"dialog.ichiban"}}):_vm._e(),(_vm.dialog.display)?_c('DialogIchibanDisplay',{attrs:{"item":_vm.selectedItem,"branches":_vm.accessBranch},on:{"load":_vm.load},model:{value:(_vm.dialog.display),callback:function ($$v) {_vm.$set(_vm.dialog, "display", $$v)},expression:"dialog.display"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }